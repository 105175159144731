body,
#container {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  display: flex;
  align-items: center;
}

#text {
  text-align: center;
  width: 100%;
}

#status {
  position: absolute;
  width: 100%;
  bottom: 1em;
  text-align: center;
}
